@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;900&display=swap');
@import "colors";

@import "InstallatieOverzicht";
@import "header";
@import "installatie";
@import "klant";
@import "home";
@import "login";
@import "fileinput";
@import "settings";
@import "project";
@import "modal";
@import "partners";

body {
  font-family: "Roboto", sans-serif;
}

div {
  box-sizing: border-box;
}

h3 {
  color: $color__verbo_blue;
  margin: 0;
  padding-bottom: 15px;
}

/* HTML: <div class="loader"></div> */
.loader {
  padding: 0 15px;
  font-size: 0.9rem;
  color: $color__verbo_blue;
}