.usertype-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 45px;
}

.login-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 45px 0;
  max-width: 350px;
  width: 100%;

  @media only screen and (max-width: 900px) {
    padding: 15px;
  }

  .login {
    padding: 30px;
    background-color: $color__light_grey;
    border-radius: 5px;
    box-shadow: $shadow__verbo_grey;
    width: 100%;

    h2 {
      color: $color__verbo_blue;
      margin: 0 0 10px 0;
      padding: 0;
      font-weight: lighter;
      font-size: 2rem;
    }

    form {
      display: grid;
      row-gap: 15px;
      grid-template-columns: 100%;
    }

    .validation {
      color: red;
      text-align: center;

      a {
        color: red;
        &:hover {
          color: $color__verbo_blue;
        }
      }
    }

    input {
      display: block;
      padding: 7.5px;
      border-radius: 2px;
      border: none;
      font-size: 1.1rem;

      &[type=submit] {
        background-color: #ffffff;
        color: $color__verbo_blue;
        &:hover {
          background-color: $color__verbo_blue;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    &.confirm {
      p {
        color: $color__dark_grey;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $color__verbo_blue;
        font-size: .8rem;
      }
    }
  }
}