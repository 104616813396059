@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;900&display=swap");
.installaties #installatieZoekveld {
  width: 100%;
  padding: 10px 5px;
  font-size: 1.1rem;
  box-sizing: border-box;
  border-radius: 0;
  border-color: #0053a0;
}
.installaties .klantOverzichtInstallatie {
  background-color: #E5E5E5;
  padding: 15px;
  display: block;
  margin: 15px;
  color: #0053a0;
  text-decoration: none;
}
.installaties .klantOverzichtInstallatie span {
  font-size: 0.9rem;
}
.installaties .klantOverzichtInstallatie h3 {
  margin: 0;
  padding: 0;
}
.installaties .klantOverzichtInstallatie:hover {
  background-color: #0053a0;
  color: #ffffff;
}
.installaties .klantOverzichtInstallatie:hover h3 {
  color: #ffffff;
}
.installaties .installatieOverzicht {
  display: block;
  padding: 20px 15px;
  text-decoration: none;
  color: #000000;
  transition: all 0.1s ease-in-out;
  border-bottom: 1px solid #E5E5E5;
}
.installaties .installatieOverzicht:hover {
  background-color: #E5E5E5;
  cursor: pointer;
}
.installaties .installatieOverzicht .algemeneInfo {
  font-weight: 600;
}
.installaties .installatieOverzicht .ticket-alert a {
  color: red;
  text-decoration: none;
  padding: 5px 0;
  display: block;
}
.installaties .installatieOverzicht .ticket-alert a:hover {
  text-decoration: underline;
  color: #0053a0;
}
.installaties .installatieOverzicht .adressen {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.installaties .installatieOverzicht .adressen label {
  font-weight: 300;
  color: #0053a0;
  font-size: 0.95rem;
  padding: 5px 0 0px;
  display: block;
  cursor: pointer;
}
.installaties .installatieOverzicht .adressen > div {
  width: 50%;
}
.installaties .emptystate {
  display: flex;
  padding: 30px;
  justify-content: center;
}
.installaties .emptystate div {
  background-color: #E5E5E5;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  padding: 30px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: lighter;
  text-align: center;
  cursor: default;
}

.App > header {
  background-color: #0053a0;
  color: #ffffff;
  font-size: 1.1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
}
.App > header .logo {
  width: 150px;
  height: auto;
  padding: 10px 15px;
  padding-left: 30px;
}
.App > header nav {
  padding-right: 15px;
}
.App > header nav a {
  color: #ffffff;
  padding: 15px;
  font-size: 1.2rem;
  text-decoration: none;
  position: relative;
}
.App > header nav a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 4px;
  left: 10px;
  height: 2px;
  background-color: #ffffff;
  transition: 0.2s all ease-in-out;
  width: 0%;
}
.App > header nav a:hover::after {
  width: calc(100% - 20px);
}

.installatie {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 15px;
}
.installatie .melding {
  padding: 0 0 3.25px 0;
  color: red;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.1rem;
}
.installatie .installatieID {
  color: #666666;
}
.installatie .header-installatie {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.installatie .header-installatie a {
  color: #0053a0;
}
.installatie h1 {
  width: 100%;
  margin: 0;
  font-size: 2rem;
}
.installatie h2 {
  width: 100%;
  margin: 0;
  padding-bottom: 15px;
}
.installatie h3 {
  margin: 0;
  padding-bottom: 0;
}
.installatie .belangrijkeInfo {
  width: 33%;
  width: 33.3333333333%;
}
@media only screen and (max-width: 640px) {
  .installatie .belangrijkeInfo {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.installatie .installatieAdres {
  width: 33%;
  width: 33.3333333333%;
  font-style: normal;
}
.installatie .installatieAdres a {
  color: #0053a0;
}
.installatie .installatieAdres a:hover {
  color: #666666;
}
@media only screen and (max-width: 640px) {
  .installatie .installatieAdres {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.installatie .klantAdres {
  width: 33%;
  width: 33.3333333333%;
  font-style: normal;
}
@media only screen and (max-width: 640px) {
  .installatie .klantAdres {
    width: 100%;
    padding-bottom: 15px;
  }
}
.installatie .klantAdres .klantlink {
  color: #0053a0;
  text-decoration: none;
}
.installatie .klantAdres .klantlink:hover {
  text-decoration: underline;
  color: #666666;
}
.installatie nav {
  width: 100%;
  clear: both;
  display: flex;
  flex-wrap: wrap;
}
.installatie nav a {
  padding: 10px 15px;
  background-color: #E5E5E5;
  text-decoration: none;
  color: black;
  margin-right: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
}
.installatie nav a.ticketten-link {
  background-color: #f60f0f;
  color: white;
}
.installatie nav a.tickettenklant-link {
  background-color: orange;
  color: white;
}
.installatie nav a.active, .installatie nav a:hover {
  background-color: #0053a0;
  color: white;
}

.installatieInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.installatieInfo .certificaten {
  width: 33%;
  width: 33.3333333333%;
  padding-bottom: 15px;
}
.installatieInfo .certificaten .certificaat {
  padding: 3px 0;
}
.installatieInfo .certificaten .certificaat a {
  color: #0053a0;
  text-decoration: none;
}
.installatieInfo .certificaten .certificaat a:hover {
  color: #666666;
  text-decoration: underline;
}
@media only screen and (max-width: 640px) {
  .installatieInfo .certificaten {
    width: 100%;
  }
}
.installatieInfo .gerelateerdeProjecten,
.installatieInfo .extraVelden {
  width: 33%;
  width: 33.3333333333%;
  padding-bottom: 15px;
}
@media only screen and (max-width: 640px) {
  .installatieInfo .gerelateerdeProjecten,
  .installatieInfo .extraVelden {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 15px;
  }
}
.installatieInfo .lekverliezen {
  width: 33%;
  width: 33.3333333333%;
  padding-bottom: 15px;
}
.installatieInfo .lekverliezen .lekverlies span {
  font-size: 0.85rem;
}
@media only screen and (max-width: 640px) {
  .installatieInfo .lekverliezen {
    width: 100%;
  }
}
.installatieInfo .componenten {
  width: 100%;
  background-color: #E5E5E5;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
}
.installatieInfo .componenten .component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #0053a0;
  padding: 5px 0;
}
.installatieInfo .componenten .component:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.installatieInfo .componenten .component .art {
  width: 150px;
  flex-grow: 0;
}
@media only screen and (max-width: 900px) {
  .installatieInfo .componenten .component .art {
    width: 75%;
    order: 1;
  }
}
.installatieInfo .componenten .component .omschrijving {
  flex-grow: 1;
  padding: 0 15px;
}
.installatieInfo .componenten .component .omschrijving a {
  color: #0053a0;
}
.installatieInfo .componenten .component .omschrijving a:hover {
  color: #666666;
}
@media only screen and (max-width: 900px) {
  .installatieInfo .componenten .component .omschrijving {
    width: 100%;
    order: 3;
    padding: 5px 0;
  }
}
.installatieInfo .componenten .component .hoeveelheid {
  flex-grow: 0;
  width: 100px;
  text-align: right;
}
@media only screen and (max-width: 900px) {
  .installatieInfo .componenten .component .hoeveelheid {
    width: 25%;
    order: 2;
  }
}

.werkbonnen {
  width: 100%;
  box-sizing: border-box;
}
.werkbonnen .werkbon {
  background-color: #E5E5E5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  display: flex;
  flex-wrap: wrap;
}
.werkbonnen .werkbon h4 {
  color: #0053a0;
  margin: 0;
  font-size: 1.1rem;
  width: 100%;
}
.werkbonnen .werkbon .id {
  font-size: 0.9rem;
}
.werkbonnen .werkbon .downloadlink {
  display: inline-block;
}
.werkbonnen .werkbon .downloadlink a {
  border: none;
  background-color: transparent;
  color: #0053a0;
  font-size: 1rem;
  padding: 0 15px;
}
.werkbonnen .werkbon .downloadlink a:hover {
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.werkbonnen .werkbon .person {
  font-size: 0.9rem;
  padding-right: 15px;
}
.werkbonnen .werkbon .opmerkingen,
.werkbonnen .werkbon .workdescr {
  width: 100%;
  padding: 15px 0;
  line-height: 1.5;
}
.werkbonnen .werkbon .opmerkingen p,
.werkbonnen .werkbon .workdescr p {
  margin: 0;
}
.werkbonnen .werkbon h5 {
  width: 100%;
  margin: 0;
  padding: 15px 0 0 0;
  font-size: 1rem;
  font-weight: 600;
}
.werkbonnen .werkbon .materialen {
  width: 100%;
}
.werkbonnen .werkbon .materialen .materiaal {
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  column-gap: 10px;
  row-gap: 15px;
  padding: 7px 0;
  border-bottom: 1px solid #0053a0;
}
@media only screen and (max-width: 900px) {
  .werkbonnen .werkbon .materialen .materiaal {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 7.5px;
    padding: 15px 0;
  }
}
.werkbonnen .werkbon .materialen .materiaal:first-child {
  padding-top: 0;
}
.werkbonnen .werkbon .materialen .materiaal:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
@media only screen and (max-width: 900px) {
  .werkbonnen .werkbon .materialen .materiaal .id {
    order: 1;
    width: 75%;
  }
}
@media only screen and (max-width: 900px) {
  .werkbonnen .werkbon .materialen .materiaal .omschrijving {
    order: 3;
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .werkbonnen .werkbon .materialen .materiaal .aantal {
    order: 2;
    width: 25%;
    text-align: right;
  }
}

.owncloud-wrapper {
  width: 100%;
}
.owncloud-wrapper .filter form {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
}
.owncloud-wrapper .filter form label.checkcontainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owncloud-wrapper .filter form label.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.owncloud-wrapper .filter form label.checkcontainer input:checked ~ .checkmark {
  background-color: #0053a0;
}
.owncloud-wrapper .filter form label.checkcontainer input:checked ~ .checkmark:after {
  display: block;
}
.owncloud-wrapper .filter form label.checkcontainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #E5E5E5;
}
.owncloud-wrapper .filter form label.checkcontainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.owncloud-wrapper .filter form label.checkcontainer:hover input ~ .checkmark {
  background-color: #666666;
}
.owncloud-wrapper .directory-wrapper {
  background-color: rgba(102, 102, 102, 0.15);
  border-radius: 7.5px 0 0 0;
}
.owncloud-wrapper .directory-wrapper .open-close-clicker {
  top: calc(-1rem - 20px);
  padding: 0 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.owncloud-wrapper .directory-wrapper.close > .directory {
  display: none;
}
.owncloud-wrapper .directory-wrapper.close > .directory-title .open-close-clicker {
  transform: rotate(180deg);
}
.owncloud-wrapper .directory-title,
.owncloud-wrapper .file {
  border-bottom: 1px solid #E5E5E5;
  width: 100%;
}
.owncloud-wrapper .directory-title {
  padding: 10px 15px;
}
.owncloud-wrapper .directory {
  padding-left: 30px;
}
.owncloud-wrapper .directory.hiddenFiles:empty::after {
  content: "Er zijn bestanden die worden verborgen";
  padding: 10px 5px;
  display: block;
}
.owncloud-wrapper > .directory:empty::after {
  content: "Geen bestanden gevonden!";
  padding-top: 15px;
  display: block;
}
.owncloud-wrapper a.file-link {
  color: #0053a0;
  text-decoration: none;
  padding: 10px 5px;
  display: block;
}
.owncloud-wrapper .fa-solid {
  padding-right: 7.5px;
}

.mail-form {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.mail-form .error {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  background-color: red;
  color: #ffffff;
  border-radius: 5px;
}
.mail-form form {
  display: flex;
  max-width: 600px;
  width: 100%;
  flex-wrap: wrap;
  padding: 30px;
  background-color: #E5E5E5;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
}
@media only screen and (max-width: 900px) {
  .mail-form form {
    max-width: none;
    margin: 0 -15px;
  }
}
.mail-form form img {
  max-width: 100%;
  width: 100px;
}
.mail-form form textarea,
.mail-form form input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 5px;
  border: none;
  font-size: 1.1rem;
}
.mail-form form textarea:last-child,
.mail-form form input:last-child {
  margin-bottom: 0;
}
.mail-form form textarea[type=submit],
.mail-form form input[type=submit] {
  background-color: white;
  padding: 15px;
  color: #0053a0;
}
.mail-form form textarea[type=submit]:hover,
.mail-form form input[type=submit]:hover {
  background-color: #0053a0;
  color: #ffffff;
  cursor: pointer;
}
.mail-form form textarea {
  height: 100px;
}
.mail-form form label {
  display: block;
  width: 100%;
}

.ticketten-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  width: 100%;
}
.ticketten-container .ticket {
  background-color: #E5E5E5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  display: flex;
  flex-wrap: wrap;
}
.ticketten-container .ticket.klant h4 {
  color: orange;
}
.ticketten-container .ticket h4 {
  color: #f60f0f;
  margin: 0;
  font-size: 1.1rem;
  width: 100%;
}
.ticketten-container .ticket h5 {
  margin: 0;
}
.ticketten-container .ticket h5 a {
  color: #0053a0;
}
.ticketten-container .ticket h5 a:hover {
  color: #666666;
}
.ticketten-container .ticket .ticket-date {
  width: 100%;
  font-size: 0.9rem;
}
.ticketten-container .ticket .inhoud {
  width: 100%;
  padding: 15px 0;
}

.klant {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 15px;
}
.klant .melding {
  padding: 0 0 3.25px 0;
  color: red;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.1rem;
}
.klant .klantID {
  color: #666666;
}
.klant .header-klant {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.klant .header-klant a {
  color: #0053a0;
}
.klant h1 {
  width: 100%;
  margin: 0;
  font-size: 2rem;
}
.klant h2 {
  width: 100%;
  margin: 0;
  padding-bottom: 15px;
}
.klant h3 {
  margin: 0;
  padding-bottom: 0;
}
.klant .belangrijkeInfo {
  width: 33%;
  width: 33.3333333333%;
}
@media only screen and (max-width: 640px) {
  .klant .belangrijkeInfo {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.klant .installatieAdres {
  width: 33%;
  width: 33.3333333333%;
  font-style: normal;
}
.klant .installatieAdres a {
  color: #0053a0;
}
.klant .installatieAdres a:hover {
  color: #666666;
}
@media only screen and (max-width: 640px) {
  .klant .installatieAdres {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.klant .klantAdres {
  width: 33%;
  width: 33.3333333333%;
  font-style: normal;
}
@media only screen and (max-width: 640px) {
  .klant .klantAdres {
    width: 100%;
    padding-bottom: 15px;
  }
}
.klant nav {
  width: 100%;
  clear: both;
  display: flex;
  flex-wrap: wrap;
}
.klant nav a {
  padding: 10px 15px;
  background-color: #E5E5E5;
  text-decoration: none;
  color: black;
  margin-right: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.klant nav a.ticketten-link {
  background-color: #f60f0f;
  color: white;
}
.klant nav a.tickettenklant-link {
  background-color: orange;
  color: white;
}
.klant nav a:hover {
  background-color: #0053a0;
  color: white;
}
.klant .installaties {
  width: 100%;
}

.klantInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}
.klantInfo .adressen {
  width: 33%;
  width: calc((100% - 30px) / 3);
  padding-bottom: 15px;
}
@media only screen and (max-width: 640px) {
  .klantInfo .adressen {
    width: 100%;
  }
}
.klantInfo .adressen .leveradres {
  padding: 15px 0;
  border-bottom: 1px solid #E5E5E5;
}
.klantInfo .adressen .leveradres:last-child {
  border-bottom: 0px;
}
.klantInfo .adressen .leveradres h4 {
  margin: 0;
}
.klantInfo .contactInfo {
  width: 33%;
  width: calc((100% - 30px) / 3);
  padding-bottom: 15px;
}
@media only screen and (max-width: 640px) {
  .klantInfo .contactInfo {
    width: 100%;
  }
}
.klantInfo .contactInfo .contact {
  padding: 15px 0;
  border-bottom: 1px solid #E5E5E5;
}
.klantInfo .contactInfo .contact:last-child {
  border-bottom: 0px;
}
.klantInfo .lekverliezen {
  width: 33%;
  width: calc((100% - 30px) / 3);
  padding-bottom: 15px;
}
@media only screen and (max-width: 640px) {
  .klantInfo .lekverliezen {
    width: 100%;
  }
  .klantInfo .lekverliezen .lekverlies:first-child {
    border-top: 1px solid #E5E5E5;
    margin-top: 15px;
  }
}
.klantInfo .componenten {
  width: 100%;
  background-color: #E5E5E5;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
}
.klantInfo .componenten .component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #0053a0;
  padding: 5px 0;
}
.klantInfo .componenten .component:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.klantInfo .componenten .component .art {
  width: 150px;
  flex-grow: 0;
}
@media only screen and (max-width: 900px) {
  .klantInfo .componenten .component .art {
    width: 75%;
    order: 1;
  }
}
.klantInfo .componenten .component .omschrijving {
  flex-grow: 1;
  padding: 0 15px;
}
.klantInfo .componenten .component .omschrijving a {
  color: #0053a0;
}
.klantInfo .componenten .component .omschrijving a:hover {
  color: #666666;
}
@media only screen and (max-width: 900px) {
  .klantInfo .componenten .component .omschrijving {
    width: 100%;
    order: 3;
    padding: 5px 0;
  }
}
.klantInfo .componenten .component .hoeveelheid {
  flex-grow: 0;
  width: 100px;
  text-align: right;
}
@media only screen and (max-width: 900px) {
  .klantInfo .componenten .component .hoeveelheid {
    width: 25%;
    order: 2;
  }
}

.projecten {
  display: flex;
  flex-wrap: wrap;
}
.projecten .project {
  display: block;
  width: 100%;
  padding: 15px;
  text-decoration: none;
  color: #000000;
  transition: all 0.1s ease-in-out;
  border-bottom: 1px solid #E5E5E5;
}
.projecten .project:hover {
  background-color: #0053a0;
  border-radius: 5px;
  color: white;
}
.projecten .project.niet-actief {
  background-color: #e5e5e5;
}
.projecten .project.niet-actief:hover {
  background-color: #666666;
}
.projecten .project .algemeneInfo {
  font-weight: bold;
}
.projecten .project .ticket-alert a {
  color: red;
  text-decoration: none;
  padding: 5px 0;
  display: block;
}
.projecten .project .ticket-alert a:hover {
  text-decoration: underline;
  color: #0053a0;
}
.projecten .project .adressen {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.projecten .project .adressen > div {
  width: 50%;
}

.home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
  gap: 30px;
}
@media only screen and (max-width: 600px) {
  .home {
    padding: 15px;
    gap: 15px;
  }
}
.home .linkCard {
  width: 45%;
  width: calc(50% - 15px);
  background-color: #E5E5E5;
  color: #0053a0;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: lighter;
  font-size: 2rem;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  transition: 0.1s all ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .linkCard:hover {
  background-color: #0053a0;
  color: #ffffff;
}
@media only screen and (max-width: 1100px) {
  .home .linkCard {
    width: 100%;
  }
}
.home form {
  width: 45%;
  width: calc(50% - 15px);
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #E5E5E5;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1100px) {
  .home form {
    width: 100%;
  }
}
.home form .form-container {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
}
.home form .form-container .formtitle {
  color: #0053a0;
  text-align: center;
  font-weight: lighter;
  font-size: 2rem;
  width: 100%;
  margin: 0;
  padding-bottom: 15px;
}
.home form .form-container .validation {
  color: red;
  padding: 15px 0 0 0;
  text-align: center;
  width: 100%;
}
.home form .form-container input[type=number] {
  border-radius: 10px 0 0 10px;
  padding: 10px 15px;
  width: calc(100% - 200px);
  box-sizing: border-box;
  border: none;
  font-size: 1.2rem;
}
.home form .form-container input[type=submit] {
  border-radius: 0 10px 10px 0;
  width: 200px;
  box-sizing: border-box;
  border: none;
  background-color: rgba(0, 83, 160, 0.1);
  font-size: 1.2rem;
  appearance: button;
}
.home form .form-container input[type=submit]:hover {
  cursor: pointer;
  background-color: #0053a0;
  color: #ffffff;
}
.home .tickettenUser {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #E5E5E5;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  display: block;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
@media only screen and (max-width: 1100px) {
  .home .tickettenUser {
    width: 100%;
  }
}
.home .tickettenUser h2 {
  color: #0053a0;
  text-align: center;
  font-weight: lighter;
  font-size: 2rem;
  width: 100%;
  margin: 0;
  padding-bottom: 15px;
}
.usertype-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 45px;
}

.login-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 45px 0;
  max-width: 350px;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-container {
    padding: 15px;
  }
}
.login-container .login {
  padding: 30px;
  background-color: #E5E5E5;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(229, 229, 229, 0.5);
  width: 100%;
}
.login-container .login h2 {
  color: #0053a0;
  margin: 0 0 10px 0;
  padding: 0;
  font-weight: lighter;
  font-size: 2rem;
}
.login-container .login form {
  display: grid;
  row-gap: 15px;
  grid-template-columns: 100%;
}
.login-container .login .validation {
  color: red;
  text-align: center;
}
.login-container .login .validation a {
  color: red;
}
.login-container .login .validation a:hover {
  color: #0053a0;
}
.login-container .login input {
  display: block;
  padding: 7.5px;
  border-radius: 2px;
  border: none;
  font-size: 1.1rem;
}
.login-container .login input[type=submit] {
  background-color: #ffffff;
  color: #0053a0;
}
.login-container .login input[type=submit]:hover {
  background-color: #0053a0;
  color: #ffffff;
  cursor: pointer;
}
.login-container .login.confirm p {
  color: #666666;
}
.login-container .login.confirm p:last-child {
  margin-bottom: 0;
}
.login-container .login.confirm a {
  color: #0053a0;
  font-size: 0.8rem;
}

.fileinput {
  margin-bottom: 15px;
  width: 100%;
}
.fileinput .custom-file-upload {
  border: 1px solid #666666;
  display: block;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
  margin: 15px 0;
}
.fileinput .custom-file-upload input[type=file] {
  display: none;
}
.fileinput .files-preview {
  padding: 0 0 15px 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 7.5px;
}
.fileinput .files-preview .file-preview {
  width: 100%;
  cursor: pointer;
}

.settings {
  padding: 30px;
}
.settings h1 {
  color: #0053a0;
  margin: 0;
  padding-bottom: 30px;
  font-weight: lighter;
  font-size: 2.5rem;
}
.settings .setting label {
  padding-left: 15px;
}
.settings .setting select {
  margin-top: 10px;
  padding: 5px 10px;
}
.settings .setting select option {
  padding: 5px 10px;
  font-size: 1rem;
}
.settings .setting .logout {
  padding: 10px 15px;
  margin-top: 15px;
  background-color: #E5E5E5;
  display: block;
  width: 75px;
  text-align: center;
  text-decoration: none;
  color: #0053a0;
  border-radius: 5px;
}
.settings .setting .logout:hover {
  background-color: #666666;
  color: white;
}

.contactInfo {
  width: 33%;
  width: calc((100% - 30px) / 3);
  padding-bottom: 15px;
}
@media only screen and (max-width: 640px) {
  .contactInfo {
    width: 100%;
  }
}
.contactInfo .contact {
  padding: 15px 0;
  border-bottom: 1px solid #E5E5E5;
}
.contactInfo .contact:last-child {
  border-bottom: 0px;
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal .modal__wrapper {
  position: absolute;
  top: 50%;
  background: #fff;
  text-align: center;
  transform: translateY(-50%);
  padding: 2rem;
  border-radius: 18px;
  box-shadow: 0 0 1rem 0 #ccc;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .modal__wrapper img {
  max-width: 90vw;
  max-height: 90vh;
}

.partners {
  padding: 30px;
}
.partners h1 {
  color: #0053a0;
  margin-top: 0;
  padding-top: 0;
}
.partners .uitleg-partners {
  margin-bottom: 30px;
}

body {
  font-family: "Roboto", sans-serif;
}

div {
  box-sizing: border-box;
}

h3 {
  color: #0053a0;
  margin: 0;
  padding-bottom: 15px;
}

/* HTML: <div class="loader"></div> */
.loader {
  padding: 0 15px;
  font-size: 0.9rem;
  color: #0053a0;
}

