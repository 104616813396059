.settings {
  padding: 30px;

  h1 {
    color: $color__verbo_blue;
    margin: 0;
    padding-bottom: 30px;
    font-weight: lighter;
    font-size: 2.5rem;
  }
  
  .setting {
    
    label {
      padding-left: 15px;
    }

    select {
      margin-top: 10px;
      padding: 5px 10px;

      option {
        padding: 5px 10px;
        font-size: 1rem;
      }
    }

    .logout {
      padding: 10px 15px;
      margin-top: 15px;
      background-color: $color__light_grey;
      display: block;
      width: 75px;
      text-align: center;
      text-decoration: none;
      color: $color__verbo_blue;
      border-radius: 5px;

      &:hover {
        background-color: $color__dark_grey;
        color: white;
      }
    }
  }
}