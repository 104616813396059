.klant {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 15px;

  .melding {
    padding: 0 0 3.25px 0;
    color: red;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.1rem;
  }

  .klantID {
    color: $color__dark_grey;
  }

  .header-klant {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    a {
      color: $color__verbo_blue;
    }
  }

  h1 {
    width: 100%;
    margin: 0;
    font-size: 2rem;
  }

  h2 {
    width: 100%;
    margin: 0;
    padding-bottom: 15px;
  }

  h3 {
    margin: 0;
    padding-bottom: 0;
  }

  .belangrijkeInfo {
    width: 33%;
    width: calc(100% / 3);

    @media only screen and (max-width: 640px) {
      width: 100%;
      border-bottom: 1px solid $color__light_grey;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }

  .installatieAdres{
    width: 33%;
    width: calc(100% / 3);
    font-style: normal;

    a {
      color: $color__verbo_blue;

      &:hover {
        color: $color__dark_grey;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
      border-bottom: 1px solid $color__light_grey;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }

  .klantAdres {
    width: 33%;
    width: calc(100% / 3);
    font-style: normal;

    @media only screen and (max-width: 640px) {
      width: 100%;
      padding-bottom: 15px;
    }
  }

  nav {
    width: 100%;
    clear: both;
    display: flex;
    flex-wrap: wrap;

    a {
      padding: 10px 15px;
      background-color: $color__light_grey;
      text-decoration: none;
      color: black;
      margin-right: 5px;
      margin-bottom: 15px;
      box-sizing: border-box;

      &.ticketten-link {
        background-color: #f60f0f;
        color: white;
      }

      &.tickettenklant-link {
        background-color: orange;
        color: white;
      }

      &:hover {
        background-color: $color__verbo_blue;
        color: white;
      }
    }
  }

  .installaties {
    width: 100%;

  }
}

.klantInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;

  .adressen {
    width: 33%;
    width: calc((100% - 30px) / 3);
    padding-bottom: 15px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    .leveradres {
      padding: 15px 0;
      border-bottom: 1px solid $color__light_grey;

      &:last-child {
        border-bottom: 0px;
      }

      h4 {
        margin: 0;

      }
    }
  }

  .contactInfo {
    width: 33%;
    width: calc((100% - 30px) / 3);
    padding-bottom: 15px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    .contact {
      padding: 15px 0;
      border-bottom: 1px solid $color__light_grey;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }

  .lekverliezen {
    width: 33%;
    width: calc((100% - 30px) / 3);
    padding-bottom: 15px;

    @media only screen and (max-width: 640px) {
      width: 100%;

      .lekverlies:first-child {
        border-top: 1px solid $color__light_grey;
        margin-top: 15px;
      }
    }
  }

  .componenten {
    width: 100%;
    background-color: $color__light_grey;
    border-radius: 5px;
    padding: 15px;
    box-shadow: $shadow__verbo_grey;

    .component {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid $color__verbo_blue;
      padding: 5px 0;
      //font-size: 1rem;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .art {
        width: 150px;
        flex-grow: 0;

        @media only screen and (max-width: 900px) {
          width: 75%;
          order: 1;
        }
      }

      .omschrijving {
        flex-grow: 1;
        padding: 0 15px;

        a {
          color: $color__verbo_blue;
          &:hover {
            color: $color__dark_grey;
          }
        }

        @media only screen and (max-width: 900px) {
          width: 100%;
          order: 3;
          padding: 5px 0;
        }
      }

      .hoeveelheid {
        flex-grow: 0;
        width: 100px;
        text-align: right;

        @media only screen and (max-width: 900px) {
          width: 25%;
          order: 2;
        }
      }
    }
  }
}

.projecten {
  display: flex;
  flex-wrap: wrap;

  .project {
    display: block;
    width: 100%;
    padding: 15px;
    text-decoration: none;
    color: #000000;
    transition: all .1s ease-in-out;

    border-bottom: 1px solid $color__light_grey;

    &:hover {
      background-color: $color__verbo_blue;
      border-radius: 5px;
      color: white;
    }

    &.niet-actief {
      background-color: transparentize($color__light_grey, 0);

      &:hover {
        background-color: $color__dark_grey;
      }
    }

    .algemeneInfo {
      font-weight: bold;
    }

    .ticket-alert {
      a {
        color: red;
        text-decoration: none;
        padding: 5px 0;
        display: block;

        &:hover {
          text-decoration: underline;
          color: $color__verbo_blue;
        }
      }
    }

    .adressen {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > div {
        width: 50%;
      }
    }
  }
}