.partners {
  padding: 30px;

  h1 {
    color: $color__verbo_blue;
    margin-top: 0;
    padding-top: 0;
  }

  .uitleg-partners {
    margin-bottom: 30px;
  }
}