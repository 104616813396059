.App > header {
  background-color: $color__verbo_blue;
  color: #ffffff;
  font-size: 1.1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-shadow: 5px 5px 5px 0px transparentize($color__light_grey, 0.5);

  .logo {
    width: 150px;
    height: auto;
    padding: 10px 15px;
    padding-left: 30px;
  }

  nav {
    padding-right: 15px;

    a {
      color: #ffffff;
      padding: 15px;
      font-size: 1.2rem;
      text-decoration: none;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 4px;
        left: 10px;
        height: 2px;
        background-color: #ffffff;
        transition: 0.2s all ease-in-out;
        width: 0%;
      }

      &:hover {
        &::after {
          width: calc(100% - 20px);
        }
      }
    }
  }
}