.installatie {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 15px;

  .melding {
    padding: 0 0 3.25px 0;
    color: red;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.1rem;
  }

  .installatieID {
    color: $color__dark_grey;
  }

  .header-installatie {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    a {
      color: $color__verbo_blue;
    }
  }

  h1 {
    width: 100%;
    margin: 0;
    font-size: 2rem;
  }

  h2 {
    width: 100%;
    margin: 0;
    padding-bottom: 15px;
  }

  h3 {
    margin: 0;
    padding-bottom: 0;
  }

  .belangrijkeInfo {
    width: 33%;
    width: calc(100% / 3);

    @media only screen and (max-width: 640px) {
      width: 100%;
      border-bottom: 1px solid $color__light_grey;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }

  .installatieAdres{
    width: 33%;
    width: calc(100% / 3);
    font-style: normal;

    a {
      color: $color__verbo_blue;

      &:hover {
        color: $color__dark_grey;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
      border-bottom: 1px solid $color__light_grey;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }

  .klantAdres {
    width: 33%;
    width: calc(100% / 3);
    font-style: normal;

    @media only screen and (max-width: 640px) {
      width: 100%;
      padding-bottom: 15px;
    }

    .klantlink {
      color: $color__verbo_blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $color__dark_grey
      }
    }
  }

  nav {
    width: 100%;
    clear: both;
    display: flex;
    flex-wrap: wrap;

    a {
      padding: 10px 15px;
      background-color: $color__light_grey;
      text-decoration: none;
      color: black;
      margin-right: 5px;
      margin-bottom: 15px;
      box-sizing: border-box;
      transition: all .1s ease-in-out;

      &.ticketten-link {
        background-color: #f60f0f;
        color: white;
      }

      &.tickettenklant-link {
        background-color: orange;
        color: white;
      }

      &.active,
      &:hover {
        background-color: $color__verbo_blue;
        color: white;
      }
    }
  }
}

.installatieInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .certificaten {
    width: 33%;
    width: calc(100% / 3);
    padding-bottom: 15px;

    .certificaat {
      padding: 3px 0;
      a {
        color: $color__verbo_blue;
        text-decoration: none;

        &:hover {
          color: $color__dark_grey;
          text-decoration: underline;
        }
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  .gerelateerdeProjecten,
  .extraVelden {
    width: 33%;
    width: calc(100% / 3);
    padding-bottom: 15px;

    @media only screen and (max-width: 640px) {
      width: 100%;
      border-bottom: 1px solid $color__light_grey;
      margin-bottom: 15px;
    }
  }

  .lekverliezen {
    width: 33%;
    width: calc(100% / 3);
    padding-bottom: 15px;

    .lekverlies {
      span {
        font-size: .85rem;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  .componenten {
    width: 100%;
    background-color: $color__light_grey;
    border-radius: 5px;
    padding: 15px;
    box-shadow: $shadow__verbo_grey;

    .component {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid $color__verbo_blue;
      padding: 5px 0;
      //font-size: 1rem;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .art {
        width: 150px;
        flex-grow: 0;

        @media only screen and (max-width: 900px) {
          width: 75%;
          order: 1;
        }
      }

      .omschrijving {
        flex-grow: 1;
        padding: 0 15px;

        a {
          color: $color__verbo_blue;
          &:hover {
            color: $color__dark_grey;
          }
        }

        @media only screen and (max-width: 900px) {
          width: 100%;
          order: 3;
          padding: 5px 0;
        }
      }

      .hoeveelheid {
        flex-grow: 0;
        width: 100px;
        text-align: right;

        @media only screen and (max-width: 900px) {
          width: 25%;
          order: 2;
        }
      }
    }
  }
}

.werkbonnen {
  width: 100%;
  box-sizing: border-box;

  .werkbon {
    background-color: $color__light_grey;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: $shadow__verbo_grey;
    display: flex;
    flex-wrap: wrap;

    h4 {
      color: $color__verbo_blue;
      margin: 0;
      font-size: 1.1rem;
      width: 100%;
    }

    .id {
      font-size: .9rem;
    }

    .downloadlink {
      display: inline-block;
      a {
        border: none;
        background-color: transparent;
        color: $color__verbo_blue;
        font-size: 1rem;
        padding: 0 15px;

        &:hover {
          background-color: transparent;
          color: white;
          cursor: pointer;
        }
      }
    }

    .person {
      font-size: .9rem;
      padding-right: 15px;
    }

    .opmerkingen,
    .workdescr {
      width: 100%;
      padding: 15px 0;
      line-height: 1.5;

      p {
        margin: 0;
      }
    }

    h5 {
      width: 100%;
      margin: 0;
      padding: 15px 0 0 0;
      font-size: 1rem;
      font-weight: 600;
    }

    .materialen {
      width: 100%;
      .materiaal {
        display: grid;
        grid-template-columns: 150px 1fr 150px;
        column-gap: 10px;
        row-gap: 15px;
        padding: 7px 0;
        border-bottom: 1px solid $color__verbo_blue;

        @media only screen and (max-width: 900px) {
          display: flex;
          flex-wrap: wrap;
          column-gap: 0;
          row-gap: 7.5px;
          padding: 15px 0;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        .id {

          @media only screen and (max-width: 900px) {
            order: 1;
            width: 75%;
          }
        }
        .omschrijving {

          @media only screen and (max-width: 900px) {
            order: 3;
            width: 100%;
          }
        }
        .aantal {

          @media only screen and (max-width: 900px) {
            order: 2;
            width: 25%;
            text-align: right;
          }
        }
      }
    }
  }
}

.owncloud-wrapper {
  width: 100%;

  .filter {

    form {
      display: flex;
      column-gap: 30px;
      flex-wrap: wrap;

      label.checkcontainer {
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1.1rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark {
            background-color: $color__verbo_blue;

            &:after {
              display: block;
            }
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: $color__light_grey;

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        &:hover input ~ .checkmark {
          background-color: $color__dark_grey;
        }
      }
    }
  }

  .directory-wrapper {
    background-color: transparentize($color__dark_grey, .85);
    border-radius: 7.5px 0 0 0;

    .open-close-clicker {
      top: calc(-1rem - 20px);
      //width: 30px;
      //left: -50px;
      padding: 0 15px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }

    &.close > .directory {
      display: none;
    }

    &.close > .directory-title {
      //background-color: $color__light_grey;
      .open-close-clicker {
        transform: rotate(180deg);
      }
    }
  }

  .directory-title,
  .file {
    border-bottom: 1px solid $color__light_grey;
    width: 100%;
  }

  .directory-title {
    //background-color: $color__light_grey;
    padding: 10px 15px;
  }

  .directory {
    padding-left: 30px;

    &.hiddenFiles {
      &:empty {
        &::after {
          content: "Er zijn bestanden die worden verborgen";
          padding: 10px 5px;
          display: block;
        }
      }
    }
  }


  & > .directory {

    &:empty {
      &::after {
        content: "Geen bestanden gevonden!";
        padding-top: 15px;
        display: block;
      }
    }
  }

  a.file-link {
    color: $color__verbo_blue;
    text-decoration: none;
    padding: 10px 5px;
    display: block;
  }
  .fa-solid {
    padding-right: 7.5px;
  }
}

.mail-form {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  .error {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    background-color: red;
    color: #ffffff;
    border-radius: 5px;
  }

  form {
    display: flex;
    max-width: 600px;
    width: 100%;
    flex-wrap: wrap;
    padding: 30px;
    background-color: $color__light_grey;
    border-radius: 5px;
    box-shadow: $shadow__verbo_grey;

    @media only screen and (max-width: 900px) {
      max-width: none;
      margin: 0 -15px;
    }

    img {
      max-width: 100%;
      width: 100px;
    }

    textarea,
    input {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      padding: 5px;
      //border-radius: 2px;
      border: none;
      font-size: 1.1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &[type=submit] {
        background-color: white;
        padding: 15px;
        color: $color__verbo_blue;

        &:hover {
          background-color: $color__verbo_blue;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    textarea {
      height: 100px;
    }

    label {
      display: block;
      width: 100%;
    }
  }
}

.ticketten-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  width: 100%;

  .ticket {
    background-color: $color__light_grey;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: $shadow__verbo_grey;
    display: flex;
    flex-wrap: wrap;

    &.klant {
      h4 {
        color: orange;
      }
    }

    h4 {
      color: #f60f0f;
      margin: 0;
      font-size: 1.1rem;
      width: 100%;
    }

    h5 {
      margin: 0;
      a {
        color: $color__verbo_blue;

        &:hover {
          color: $color__dark_grey;
        }
      }
    }

    .ticket-date {
      width: 100%;
      font-size: 0.9rem;
    }

    .inhoud {
      width: 100%;
      padding: 15px 0;
    }
  }
}