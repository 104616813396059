.installaties {

  #installatieZoekveld {
    width: 100%;
    padding: 10px 5px;
    font-size: 1.1rem;
    box-sizing: border-box;
    border-radius: 0;
    border-color: $color__verbo_blue;
  }

  .klantOverzichtInstallatie {
    background-color: $color__light_grey;
    padding: 15px;
    display: block;
    margin: 15px;
    color: $color__verbo_blue;
    text-decoration: none;

    span {
      font-size: 0.9rem;
    }

    h3 {
      margin: 0;
      padding: 0;
    }

    &:hover {
      background-color: $color__verbo_blue;
      color: #ffffff;

      h3 {
        color: #ffffff;
      }
    }
  }

  .installatieOverzicht {
    display: block;
    padding: 20px 15px;
    text-decoration: none;
    color: #000000;
    transition: all .1s ease-in-out;

    border-bottom: 1px solid $color__light_grey;

    &:hover {
      background-color: $color__light_grey;
      cursor: pointer;
    }

    .algemeneInfo {
      font-weight: 600;
    }

    .ticket-alert {
      a {
        color: red;
        text-decoration: none;
        padding: 5px 0;
        display: block;

        &:hover {
          text-decoration: underline;
          color: $color__verbo_blue;
        }
      }
    }

    .adressen {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      label {
        font-weight: 300;
        color: $color__verbo_blue;
        font-size: 0.95rem;
        padding: 5px 0 0px;
        display: block;

        cursor: pointer;
      }

      & > div {
        width: 50%;
      }
    }
  }

  .emptystate {
    display: flex;
    padding: 30px;
    justify-content: center;

    div {
      background-color: $color__light_grey;
      box-shadow: 5px 5px 5px 0px transparentize($color__light_grey, 0.5);
      padding: 30px;
      border-radius: 5px;
      font-size: 1.2rem;
      font-weight: lighter;
      text-align: center;
      cursor: default;
    }
  }
}