.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;   z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .modal__wrapper {
    position: absolute;
    top: 50%;
    //width: 30%;
    background: #fff;
    text-align: center;
    transform: translateY(-50%);
    padding: 2rem;
    border-radius: 18px;
    box-shadow: 0 0 1rem 0 #ccc;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 90vw;
      max-height: 90vh;
    }
  }
}