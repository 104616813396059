.home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
  gap: 30px;

  @media only screen and (max-width: 600px) {
    padding: 15px;
    gap: 15px;
  }

  .linkCard {
    width: 45%;
    width: calc(50% - 15px);
    background-color: $color__light_grey;
    color: $color__verbo_blue;
    text-align: center;
    padding: 50px;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: lighter;
    font-size: 2rem;
    border-radius: 5px;
    box-shadow: $shadow__verbo_grey;
    transition: 0.1s all ease-in-out;
    display:flex;
    justify-content:center;
    align-items:center;

    &:hover {
      background-color: $color__verbo_blue;
      color: #ffffff;
    }

    @media only screen and (max-width: 1100px) {
      width: 100%;
      //margin-bottom: 30px;
    }

    @media only screen and (max-width: 600px) {
      //width: calc(100% - 30px);
      //margin-bottom: 15px;
    }
  }

  form {
    width: 45%;
    width: calc(50% - 15px);
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $color__light_grey;
    box-shadow: $shadow__verbo_grey;
    display:flex;
    justify-content:center;
    align-items:center;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }

    @media only screen and (max-width: 600px) {
      //width: calc(100% - 30px);
    }

    .form-container {
      padding: 30px;
      display: flex;
      flex-wrap: wrap;

      .formtitle {
        color: $color__verbo_blue;
        text-align: center;
        font-weight: lighter;
        font-size: 2rem;
        width: 100%;
        margin: 0;
        padding-bottom: 15px;
      }

      .validation {
        color: red;
        padding: 15px 0 0 0;
        text-align: center;
        width: 100%;
      }

      input[type="number"] {
        border-radius: 10px 0 0 10px;
        padding: 10px 15px;
        width: calc(100% - 200px);
        box-sizing: border-box;
        border: none;
        font-size: 1.2rem;
      }

      input[type="submit"] {
        border-radius: 0 10px 10px 0;
        width: 200px;
        box-sizing: border-box;
        border: none;
        background-color: fade-out($color__verbo_blue, 0.9);
        font-size: 1.2rem;
        appearance: button;

        &:hover {
          cursor: pointer;
          background-color: $color__verbo_blue;
          color: #ffffff;
        }
      }
    }
  }

  .tickettenUser {
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $color__light_grey;
    box-shadow: $shadow__verbo_grey;
    display:block;
    justify-content:center;
    align-items:center;
    padding: 30px;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }

    h2 {
      color: $color__verbo_blue;
      text-align: center;
      font-weight: lighter;
      font-size: 2rem;
      width: 100%;
      margin: 0;
      padding-bottom: 15px;
    }
  }

  .tickettenlijst {

  }
}