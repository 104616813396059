.contactInfo {
  width: 33%;
  width: calc((100% - 30px) / 3);
  padding-bottom: 15px;

  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  .contact {
    padding: 15px 0;
    border-bottom: 1px solid $color__light_grey;

    &:last-child {
      border-bottom: 0px;
    }
  }
}