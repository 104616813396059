.fileinput {
  margin-bottom: 15px;
  width: 100%;

  .custom-file-upload {
    border: 1px solid $color__dark_grey;
    display: block;
    padding: 15px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 15px 0;

    input[type="file"] {
      display: none;
    }
  }

  .files-preview {
    padding: 0 0 15px 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 7.5px;
    .file-preview {
      width: 100%;
      cursor: pointer;
    }
  }
}